/* global algoliasearch instantsearch 

const searchClient = algoliasearch('latency', '6be0576ff61c053d5f9a3225e2a90f76');

const search = instantsearch({
  indexName: 'instant_search',
  searchClient,
});

*/
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
const search = instantsearch({
  indexName: 'datasets',
  searchClient: instantMeiliSearch(
    'http://35.232.184.215',
    '0c03cdf5572a83db2a9d90bb9003ca6fcc47d9ab06c3330b41709bb4138a981b',

    {
      paginationTotalHits: 200, // default: 200.
    }
  ),
});


var hitTemplate =
  '<div class="hit media">' +
  '<div class="media-left">' +
  // '<div class="media-object" style="background-image: url(\'{{image}}\');"></div>' +
  '</div>' +
  '<div class="media-body">' +
  '<h4 class="media-heading"> <a href="{{#helpers.highlight}}{"attribute": "link"}{{/helpers.highlight}}">{{#helpers.highlight}} { "attribute": "name" }{{/helpers.highlight}} </a></h4>' +
  '<h3 class="media-heading">Class: {{#helpers.highlight}}{ "attribute": "classes" }{{/helpers.highlight}} </h3>' +
  '<p class="year">Description: {{description}}</p>' +
  '</div>' +
  '</div>';

var noResultsTemplate =
  '<div class="text-center">No results found matching <strong>{{query}}</strong>.</div>';


search.addWidgets([
  instantsearch.widgets.searchBox({
    container: '#searchbox',
	    showReset: false,
    cssClasses: {
      root: 'root',
      form: 'form',
      input: 'input form-control',
      submit: 'btn btn-default',
      reset: 'btn btn-default',
    },
  }),
  instantsearch.widgets.hits({
    container: '#hits',
	templates: {
      empty: noResultsTemplate,
      item: hitTemplate,
    },
    cssClasses: {
      list: 'list',
    },
  }),
instantsearch.widgets.pagination({
    container: '#pagination',
    cssClasses: {
      root: 'root',
      list: 'pagination',
      disabledItem: 'disabledItem',
      selectedItem: 'selectedItem',
    },
  }),
  instantsearch.widgets.refinementList({
  container: '#refinement-list',
  attribute: 'name',
  operator: 'or',
  showMore: true,
  showMoreLimit: 40,
  cssClasses: {
      list: 'nav nav-list',
      count: 'badge pull-right',
      selectedItem: 'active',
      item: 'item',
    },
	}),
]);

search.start();
